/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "../hooks/useForm";

const initialForm = {
  fullName: "",
  phoneNumber: "",
  email: "",
  asesoria: "",
  description: "",
};

const validationsForm = (form) => {
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexPhoneNumber = /^\d{9}$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;

  if (!form.fullName.trim()) {
    errors.fullName = "Este campo es requerido";
  } /*else if (!regexName.test(form.name.trim())) {
    errors.name = "El nombre solo usa letra y espacios en blanco";
  }*/

  if (!form.phoneNumber.trim()) {
    errors.phoneNumber = "Este campo es requerido";
  } /*else if (!regexPhoneNumber.test(form.phoneNumber.trim())) {
    errors.phoneNumber = "El numero debe tener una cantidad de 8 dígitos";
  }*/

  if (!form.email.trim()) {
    errors.email = "Este campo es requerido";
  } /*else if (!regexEmail.test(form.email.trim())) {
    errors.email = "El correo debe ser valido";
  }*/

  if (!form.description.trim()) {
    errors.description = "Este campo es requerido";
  }

  if (!form.asesoria.trim()) {
    errors.asesoria = "Este campo es requerido";
  } /*else if (form.asesoria === "Cuál asesoria quieres consultar?") {
    errors.asesoria = "Se requiere eleccion de asesoria";
  }*/

  return errors;
};

const ContactForm = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);
  return (
    <div>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-7">
          <label htmlFor="firstName" className="form-label">
            <i className="fa fa-user-circle-o" aria-hidden="true"></i> Nombre
            Completo
          </label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            name="fullName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={form.fullName}
            required
          />
        </div>
        <div className="col-md-5">
          <label htmlFor="phoneNumber" className="form-label">
            <i className="fa fa-phone" aria-hidden="true"></i> Número de
            Teléfono
          </label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            value={form.phoneNumber}
            required
          />
        </div>
        <div className="col-md-7">
          <label htmlFor="emailInfo" className="form-label">
            <i className="fa fa-envelope" aria-hidden="true"></i> Correo
            Electrónico
          </label>
          <div className="input-group"></div>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={form.email}
            required
          />
        </div>
        <div className="col-md-5">
          <label htmlFor="tipoAsesoria" className="form-label">
            <i className="fa fa-suitcase" aria-hidden="true"></i>
            Asesoría
          </label>
          <select
            id="asesoria"
            className="form-select"
            name="asesoria"
            onChange={handleChange}
            value={form.asesoria}
            required
          >
            <option selected>Cuál asesoría quieres consultar?</option>
            <option value="tributaria">Consultar asesoría tributaria</option>
            <option value="legal">Consultar asesoría legal</option>
            <option value="comercial">Consultar asesoría comercial</option>
            <option value="financiera">Consultar asesoría financiera</option>
          </select>
        </div>
        <div className="col-md-12">
          <label htmlFor="comments" className="form-label">
            <i className="fa fa-comment" aria-hidden="true"></i> Descripción del
            caso
          </label>
          <textarea
            className="form-control"
            id="description"
            rows="3"
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={form.description}
            required
          ></textarea>
        </div>
        <div className="col-xl-2 col-md-3">
          <button
            type="submit"
            className="btn btn-outline-primary btn-lg primary-btn"
            value="Enviar"
            id="sumbitButton"
          >
            <i className="fa fa-paper-plane" aria-hidden="true"></i> Enviar
          </button>
        </div>
        <div className="col-xl-10 col-md-9">
          {response && (
            <div className="alert alert-success text-center" role="alert">
              Formulario enviado, te contactaremos a la brevedad
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
