import React from "react";
import { NavLink } from "react-router-dom";
class Footer extends React.Component {
  render() {
    return (
      <footer>
        <section className="pt-3">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Recurso Tributario & Asociados
                </h6>
                <p>Detrás de una empresa exitosa está RT & Asociados</p>
                <p>Se parte de nuestra empresa, contáctanos.</p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Asesorías</h6>
                <p>
                  <NavLink to="../services" className="text-reset">
                    Tributaria
                  </NavLink>
                </p>
                <p>
                  <NavLink to="../services" className="text-reset">
                    Legal
                  </NavLink>
                </p>
                <p>
                  <NavLink to="../services" className="text-reset">
                    Comercial
                  </NavLink>
                </p>
                <p>
                  <NavLink to="../services" className="text-reset">
                    Financiera
                  </NavLink>
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Páginas Útiles</h6>
                <p>
                  <NavLink to="../" className="text-reset">
                    Inicio
                  </NavLink>
                </p>
                <p>
                  <NavLink to="../contact" className="text-reset">
                    Contacto
                  </NavLink>
                </p>
                <p>
                  <NavLink to="../" className="text-reset">
                    Términos y Condiciones
                  </NavLink>
                </p>
                <p>
                  <NavLink to="../about" className="text-reset">
                    Nosotros
                  </NavLink>
                </p>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
                <p>
                  <i className="fa fa-envelope me-3 text-secondary"></i>
                  gerencia@recursotributario.cl
                </p>
                <p>
                  <i className="fa fa-phone me-3 text-secondary"></i>+569 8227
                  8966
                </p>
              </div>
            </div>
          </div>
        </section>
        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.025)" }}
        >
          © 2022 Copyright RT & Asociados
        </div>
      </footer>
    );
  }
}

export default Footer;
