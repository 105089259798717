import React from "react";
import { Helmet } from "react-helmet";
import Config from "../config.json";
import { ContactForm } from "../components";
const TITLE = "Contacto - " + Config.SITE_TITLE;
const DESC = "";
const CANONICAL = Config.SITE_DOMAIN + "/contact";
function Contact() {
  return (
    <main>
      <Helmet>
        <title>{TITLE}</title>
        <link rel="canonical" href={CANONICAL}></link>
        <meta name="description" content={DESC}></meta>
        <meta name="theme-color" content={Config.THEME_COLOR}></meta>
      </Helmet>
      <div className="bg-expand mb-5">
        <div className="container">
          <div className="row d-flex text-end">
            <div className="me-5 mb-5">
              <h2 className="primary-text">¡Tu puedes generar un cambio!</h2>
              <p className="lead">
                Para ayudarte en la asesoría que necesitas te invitamos a
                completar formulario adjunto.
              </p>
              <p className="lead">
                Una vez que lo envíes uno de nuestros ejecutivos te contactará
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-5">
            <h2 className="text-center mb-5 primary-text">
              Cuéntanos sobre tu caso
            </h2>
            <ContactForm />
          </div>
          <div className="col-lg-4">
            <div className="text-center">
              <h4>Contáctanos en nuestras redes sociales</h4>
            </div>
            <div className="d-flex flex-lg-column flex-row align-items-center justify-content-around">
              <a
                href="https://wa.me/+56955364915"
                className="icon icon-whatsapp"
              >
                <i className="fa fa-whatsapp icon-contact-fixed"></i>
              </a>
              <a
                href="https://instagram.com/recursotributario?igshid=YmMyMTA2M2Y="
                className="icon icon-instagram"
              >
                <i className="fa fa-instagram icon-contact-fixed"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/recurso-tributario-asociados-spa/"
                className="icon icon-linkedin"
              >
                <i className="fa fa-linkedin icon-contact-fixed"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contact;
