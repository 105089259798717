import React from "react";
import { Helmet } from "react-helmet";
import Config from "../config.json";

const TITLE = "Nosotros - " + Config.SITE_TITLE;
const DESC = "";
const CANONICAL = Config.SITE_DOMAIN + "/about";
function About() {
  return (
    <main>
      <Helmet>
        <title>{TITLE}</title>
        <link rel="canonical" href={CANONICAL}></link>
        <meta name="description" content={DESC}></meta>
        <meta name="theme-color" content={Config.THEME_COLOR}></meta>
      </Helmet>
      <a
        href="https://wa.me/+56955364915 "
        className="static-contact"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp icon-contact"></i>
      </a>
      <div className="container marketing">
        <div className="py-5 my-3 text-center">
          <img
            src={require("../images/imAb.jpg")}
            className="mx-auto"
            style={{
              maxHeight: "400px",
              maxWidth: "300px",
              borderRadius: "30%",
            }}
            alt=""
          />
          <h1 className="display-5 fw-bold">Conoce a nuestro equipo</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              Profesionales responsables, expertos en las materias de sus áreas
              respectivas, buscando siempre conocer a sus clientes y socios
              estratégicos para asesorarlos y ayudarlos en cumplir con sus
              expectativas, manteniendo una sólida relación en el tiempo.
            </p>
          </div>
        </div>
        <hr className="featurette-divider" />
        <div className="row featurette mt-5 team-item align-items-center">
          <div className="col-md-8">
            <p className="lead">
              Profesional con vasta experiencia en el mercado financiero y
              comercial, muy comprometida mantener sólidas relaciones
              comerciales con sus clientes en el tiempo, donde su foco principal
              es abordar las más diversas necesidades de ellos, consiguiendo
              siempre la satisfacción y por ende su fidelidad en el tiempo.
            </p>
            <small className="h5 text-muted">
              Paulina Nieto - Socio Fundador
            </small>
          </div>
          <div className="col-md-4 justify-content-center d-flex">
            <img
              src={require("../images/equipo/Equipo0.png")}
              alt=""
              height="200"
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;
