import React from "react";
import { Helmet } from "react-helmet";
import Config from "../config.json";
import { Link } from "react-router-dom";

const TITLE = "Servicios - " + Config.SITE_TITLE;
const DESC = "";
const CANONICAL = Config.SITE_DOMAIN + "/services";

function Services() {
  return (
    <main>
      <Helmet>
        <title>{TITLE}</title>
        <link rel="canonical" href={CANONICAL}></link>
        <meta name="description" content={DESC}></meta>
        <meta name="theme-color" content={Config.THEME_COLOR}></meta>
      </Helmet>
      <a
        href="https://wa.me/+56955364915 "
        className="static-contact"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp icon-contact"></i>
      </a>
      <div className="container my-5">
        <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <h1 className="display-4 fw-bold lh-1 primary-text">
              Conoce nuestros distintos servicios
            </h1>
          </div>
          <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
            <img
              src={require("../images/imgServ.jpg")}
              alt=""
              className="rounded-lg-3"
              style={{ maxHeight: "500px" }}
            />
          </div>
        </div>
        <div className="mt-5">
          <div
            className="row p-4 pb-0 px-lg-0 pt-lg-5 mt-5 align-items-center rounded-3 border shadow-lg justify-content-between"
            id="ServTri"
          >
            <div className="col-lg-4 col-lg-1 p-0 overflow-hidden shadow-lg">
              <img
                src={require("../images/asesorias/imgTri.jpg")}
                alt=""
                className="rounded-lg-3"
                style={{ maxHeight: "500px" }}
              />
            </div>
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3 text-end">
              <h1 className="display-4 fw-bold lh-1 primary-text">
                Asesoría Tributaria
              </h1>
              <p className="lead">
                Coméntanos tus necesidades, nos comprometemos a entregar la
                mejor experiencia en solucionar tus dificultades para el
                cumplimiento de tus obligaciones tributarias, velar por optar al
                régimen de tributación más apropiado a tú estructura de capital
                de trabajo y a la confirmación de tu empresa, adicionalmente
                informándote de los beneficios tributarios y requisitos que se
                deben cumplir. Nuestros expertos evalúan desde los procesos
                contables, para respaldar tus declaraciones en forma apropiada y
                corregir cualquier impugnación que el SII detecta. Así también
                presentar los recursos pertinentes ante actos notificados por la
                entidad fiscalizadora, con representación en instancias
                administrativas y judicial ante los tribunales tributarios
                aduaneros.
              </p>
              <div className="d-grid d-md-flex justify-content-md-end md-4 md-lg-3">
                <Link
                  to="../contact"
                  className="btn secondary-btn btn-lg px-4 me-md-2 fw-bold"
                >
                  Solicitar Asesoría
                </Link>
              </div>
            </div>
          </div>
          <div
            className="row p-4 pb-0 px-lg-0 pt-lg-5 mt-5 align-items-center rounded-3 border shadow-lg"
            id="ServLe"
          >
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
              <h1 className="display-4 fw-bold lh-1 secondary-text">
                Asesoría Legal
              </h1>
              <p className="lead">
                Asesórate con nuestros profesionales y haz que tu organización
                y/o socios sean representados en instancias Civiles, Tributarias
                y Labores, para defender tus intereses que son nuestra
                prioridad. Entregamos servicios de revisiones contractuales para
                resguardar futuros conflictos de incumplimientos y apoyarte en
                gestiones de cobranza, asi también en registros comerciales de
                deudores y cumplimiento de requisitos para el castigo de
                deudores incobrables. ¡Cuéntanos que necesitas!
              </p>
              <div className="d-grid d-md-flex justify-content-md-start md-4 md-lg-3">
                <Link
                  to="../contact"
                  className="btn primary-btn btn-lg px-4 me-md-2 fw-bold"
                >
                  Solicitar Asesoría
                </Link>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
              <img
                src={require("../images/asesorias/imgLe.jpeg")}
                alt=""
                className="rounded-lg-3"
                style={{ maxHeight: "475px" }}
              />
            </div>
          </div>
          <div
            className="row p-4 pb-0 px-lg-0 pt-lg-5 mt-5 align-items-center rounded-3 border shadow-lg justify-content-between"
            id="ServCo"
          >
            <div className="col-lg-4 col-lg-1 p-0 overflow-hidden shadow-lg">
              <img
                src={require("../images/asesorias/imgCo.jpeg")}
                alt=""
                className="rounded-lg-3"
                style={{ maxHeight: "500px" }}
              />
            </div>
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3 text-end">
              <h1 className="display-4 fw-bold lh-1 primary-text">
                Asesoría Comercial
              </h1>
              <p className="lead">
                Nuestros profesionales han dejado sus puestos gerenciales y se
                han sacado sus corbatas…. Siendo actualmente emprendedores y
                dado a sus experiencias, han logrado el avance de sus negocios.
                Asesórate con nosotros ponemos a tu conocimiento estrategias
                efectivas que te permitan lograr un crecimiento mayor. Te
                asesoramos para que tú servicio y/o producto amplie fronteras.
                Contáctanos
              </p>
              <div className="d-grid d-md-flex justify-content-md-end md-4 md-lg-3">
                <Link
                  to="../contact"
                  className="btn secondary-btn btn-lg px-4 me-md-2 fw-bold"
                >
                  Solicitar Asesoría
                </Link>
              </div>
            </div>
          </div>
          <div
            className="row p-4 pb-0 px-lg-0 pt-lg-5 mt-5 align-items-center rounded-3 border shadow-lg"
            id="ServFin"
          >
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
              <h1 className="display-4 fw-bold lh-1 secondary-text">
                Asesoría Financiera
              </h1>
              <p className="lead">
                Preparamos reportes financieros de tu empresa bajo las normas
                IFRS, análisis de los flujos efectivos que sustentan los estados
                financieros y otros requerimientos que tu empresa necesite.
                Nuestros profesionales evalúan la situación actual para luego
                informar de los procesos que deben ser intervenidos para lograr
                mejores rendimientos de los activos líquidos y los de inversión.
                Sabemos que es importante para ti proteger tu organización,
                contra los riesgos que decrementan tus resultados, siendo
                nuestro foco detectarlos preventivamente y corregir aquellos
                procesos que lo originan. ¿Necesitas evaluar tus riesgos o
                recibir una propuesta de asesoría?, contáctanos
              </p>
              <div className="d-grid d-md-flex justify-content-md-start md-4 md-lg-3">
                <Link
                  to="../contact"
                  className="btn primary-btn btn-lg px-4 me-md-2 fw-bold"
                >
                  Solicitar Asesoría
                </Link>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
              <img
                src={require("../images/asesorias/imgFin.jpg")}
                alt=""
                className="rounded-lg-3"
                style={{ maxHeight: "500px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Services;
