import React from "react";
import { NavLink } from "react-router-dom";
class Header extends React.Component {
  render() {
    return (
      <header className="header py-3 mb-0 container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a
              href="../"
              className="mb-3 mb-md-0 text-dark text-decoration-none navbar-brand"
            >
              <img
                src={require("../images/logo/logo1.png")}
                alt=""
                height="75"
              />
            </a>
            <div className="d-none d-sm-block">
              <h5 className="ms-1 me-md-auto primary-text">
                Detrás de una empresa exitosa está RT & Asociados
              </h5>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#headerNavbar"
              aria-controls="headerNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="headerNavbar"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="../" className="nav-link h5 mb-0" exact="true">
                    Inicio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="../services" className="nav-link h5 mb-0">
                    Servicios
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="../contact" className="nav-link h5 mb-0">
                    Contacto
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="../about" className="nav-link h5 mb-0">
                    Nosotros
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
export default Header;
