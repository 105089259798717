/* eslint-disable prettier/prettier */
import React from "react";
import { Helmet } from "react-helmet";
import Config from "../config.json";
import { Link } from "react-router-dom";

const TITLE = "Inicio - " + Config.SITE_TITLE;
const DESC = "";
const CANONICAL = Config.SITE_DOMAIN + "/";
class Home extends React.Component {
  render() {
    return (
      <main>
        <Helmet>
          <title>{TITLE}</title>
          <link rel="canonical" href={CANONICAL}></link>
          <meta name="description" content={DESC}></meta>
          <meta name="theme-color" content={Config.THEME_COLOR}></meta>
        </Helmet>
        <div
          id="carouselMain"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselMain"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMain"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselMain"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active data-bs-interval=4000">
              <img
                src={require("../images/carousel/imgH1.jpg")}
                className="d-block cover"
                alt="..."
              />
              <div className="container">
                <div className="carousel-caption">
                  <h1 className="primary-text display-4">
                    <strong>
                      Bienvenidos a Recurso Tributario & Asociados
                    </strong>
                  </h1>
                  <p className="h2 secondary-text">
                    Gestión y planificación para la creación de valor en los
                    procesos de tu empresa
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item data-bs-interval=4000">
              <img
                src={require("../images/carousel/imgH2.jpg")}
                className="d-block cover"
                alt="..."
              />
              <div className="carousel-caption">
                <h1 className="primary-text display-3">
                  <strong>Queremos cubrir tus necesidades</strong>
                </h1>
                <p className="h2 secondary-text">
                  Identificando las prioridades y los principios de tu empresa
                </p>
              </div>
            </div>
            <div className="carousel-item data-bs-interval=4000">
              <img
                src={require("../images/carousel/imgH3.jpg")}
                className="d-block cover"
                alt="..."
              />
              <div className="carousel-caption">
                <h1 className="primary-text display-4">
                  <strong>
                    Buscamos otorgar las decisiones correctas que necesitas
                  </strong>
                </h1>
                <p className="h2 secondary-text">
                  Junto a profesionales especializados en distintas áreas
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselMain"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselMain"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <a
          href="https://wa.me/+56955364915 "
          className="static-contact"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-whatsapp icon-contact"></i>
        </a>
        <div className="container">
          <div className="d-flex justify-content-around my-5 row row-cols-2 row-cols-lg-4">
            <div className="card col m-2" style={{ width: "18rem" }}>
              <img
                src={require("../images/asesorias/imgTri.jpg")}
                className="card-img-top"
                alt="..."
                height="200"
              />
              <div className="card-body">
                <h5 className="card-title secondary-text">
                  Asesoría Tributaria
                </h5>
                <p className="card-text">
                  Es nuestra fortaleza y queremos que seas parte del éxito
                  obtenido por nuestros actuales clientes ante actos
                  administrativos; notificaciones, citaciones, liquidaciones que
                  emite el SII
                </p>
                <Link to="services" className="btn secondary-btn">
                  Ver más
                </Link>
              </div>
            </div>
            <div className="card col m-2" style={{ width: "18rem" }}>
              <img
                src={require("../images/asesorias/imgLe.jpeg")}
                className="card-img-top"
                alt="..."
                height="200"
              />
              <div className="card-body">
                <h5 className="card-title primary-text">Asesoría Legal</h5>
                <p className="card-text">
                  Necesitas responder a demandas civiles, herencias,
                  constitución y/o modificaciones societarias, nosotros te
                  representamos legal y tributariamente
                </p>
                <Link to="services" className="btn primary-btn">
                  Ver más
                </Link>
              </div>
            </div>
            <div className="card col m-2" style={{ width: "18rem" }}>
              <img
                src={require("../images/asesorias/imgCo.jpeg")}
                className="card-img-top"
                alt="..."
                height="200"
              />
              <div className="card-body">
                <h5 className="card-title secondary-text">
                  Asesoría Comercial
                </h5>
                <p className="card-text">
                  ¿Sientes que tu emprendimiento no avanza? ¿Necesitas clientes
                  nuevos o retener a los que ya te conocen?
                </p>
                <Link to="services" className="btn secondary-btn">
                  Ver más
                </Link>
              </div>
            </div>
            <div className="card col m-2" style={{ width: "18rem" }}>
              <img
                src={require("../images/asesorias/imgFin.jpg")}
                className="card-img-top"
                alt="..."
                height="200"
              />
              <div className="card-body">
                <h5 className="card-title primary-text">Asesoría Financiera</h5>
                <p className="card-text">
                  Evalúa la sanidad de los flujos efectivos en tu empresa.
                  Obtén un balance financiero actual y con protección.
                </p>
                <Link to="services" className="btn primary-btn">
                  Ver más
                </Link>
              </div>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="row featurette py-5">
            <div className="col-md-7 mt-5">
              <div className="text-center">
                <h2 className="primary-text display-4">Propuesta de Valor</h2>
              </div>
              <p className="lead">
                En RT & ASOCIADOS nos comprometemos a entregarte servicios bajo
                un <strong className="secondary-text h5">Proceso Planificado</strong>, siendo una propuesta valiosa para tu
                toma de decisiones. Nuestra ética toma gran relevancia con todos
                nuestros clientes y socios estratégicos, bajo el compromiso de
                crear valor en los procesos de tu empresa. Desarrollamos
                elementos funcionales, sistemáticos y oportunos para <strong className="primary-text h5">cubrir tus
                necesidades</strong>. Es por esto que nuestros clientes nos eligen,
                confían en nuestros profesionales y en su enfoque en identificar
                las prioridades y principios de cada uno de ellos, de manera tal
                otorgarles siempre información relevante y precisa para la toma
                de decisiones correctas y oportunas.
              </p>
            </div>
            <div className="col-md-5 d-flex justify-content-center align-self-center">
              <img
                src={require("../images/imgV2.jpg")}
                alt=""
                className="img-thumbnail img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </div>
          </div>

          <div className="row featurette py-5 bg-item">
            <div className="col-lg-5 d-flex justify-content-center align-self-center mt-5">
              <img
                src={require("../images/imgV1.jpg")}
                alt=""
                className="img-thumbnail img-fluid"
                style={{ maxHeight: "450px", maxWidth: "320px" }}
              />
            </div>
            <div className="col-lg-7 mt-5">
              <div className="text-center">
                <h2 className="secondary-text display-4">
                  Nuestra Estrategia de Negocio
                </h2>
              </div>
              <p className="lead">
                RT & ASOCIADOS se conforma de profesionales y socios
                estratégicos con actualizados conocimientos técnicos y con el
                enfoque de ir incorporando los factores actuales y futuros de
                los mercados nacionales e internacionales, profundizándolos con
                estudios y/o investigaciones, logrando un sustento en las
                propuestas entregadas a los clientes con procesos efectivos y
                conocidos en cada una de las etapas.
              </p>
              <p className="lead">
                Ponemos a tu disposición nuestras distintas asesorías, para el
                desarrollo y crecimiento de tus gestiones, para que logres
                sobrepasar las dificultades del entorno o propias de tu empresa
                que no te permiten seguir avanzando en el logro de tus
                objetivos. RT & Asociados te permitirá disminuir los riesgos de
                tu organización permitiéndote redireccionar estructuras
                organizacionales que requieren de un apoyo concreto e
                incorporar <strong className="secondary-text h5">mayor peso económico para tu negocio.</strong> Estamos
                convencidos que a mayor <strong className="primary-text h5">responsabilidad social</strong> las
                organizaciones deben fortalecer tus niveles de control y
                autogestión.
              </p>
            </div>
          </div>
          <hr className="featurette-divider" />
          <div className="row mb-5">
            <div className="news-container">
              <div className="text-center mt-3 news-header">
                <h4 className="primary-text">
                  Te invitamos a revisar las siguientes noticias
                </h4>
              </div>
              <div className="col d-flex flex-wrap justify-content-around align-items-center">
                <div>
                </div>
                <div className="news-item">
                  <h3 className="text-center">¿Sabías que?</h3>
                  <p>
                    La ley 21.462 de fecha 26-JUL-2022, modificó algunos temas de
                    la reciente Reforma Tributaria; introduce modificaciones con
                    el objeto de eliminar o reducir un conjunto de exenciones
                    tributarias, con el fin de aumentar la recaudación fiscal de
                    forma permanente, considerando especialmente la necesidad de
                    financiar la recientemente creada pensión garantizada
                    universal (PGU), sancionada por ley 21.419
                  </p>
                </div>
                <div className="news-item">
                  <h3 className="text-center">
                    ¡¡¡Entérate del proyecto de reforma tributaria 2023!!!!
                  </h3>
                  <p>
                    El cual pretende en el 2023 la recaudación llegará a un 0,6%
                    del PIB para ir aumentando progresivamente a 1,8% en 2024, a
                    3,1% en 2025 hasta llegar a un 4,1% en régimen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default Home;
